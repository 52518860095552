.question-modules {
  flex: 1;

  .header-block {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.375rem;
      color: #545454;
      font-weight: bold;
      padding-right: 7.5rem;
      padding-bottom: 2.5rem;
      border-bottom: 1px solid #d8d8d8;
    }

    h6 {
      color: #aba5b8;
      font-size: 1.05rem;
      padding-bottom: 2.5rem;
    }
  }

  .search-block {
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-search {
      display: flex;
      align-items: center;
    }

    &-import {
      display: flex;
      align-items: center;
    }

    h3 {
      color: #aba5bb;
      font-size: 1.25rem;
      margin-right: 1.5rem;

      span {
        color: #545454;
      }
    }
  }

  .search-education-block {
    // margin-top: 3rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
    }

    h3 {
      color: $text-muted;
      span {
        color: $color-black;
        font-family: $font-medium;
      }
    }
  }

  .card-section {
    margin-top: 3.75rem;

    .no-search {
      margin-left: 1rem;
      p {
        font-size: 1.5rem;
      }
      .suggestions {
        color: black;
        p {
          margin-bottom: 0.9rem;
        }
        ul {
          li {
            font-size: 1.2rem;
          }
        }
      }
      a {
        color: $primary-color;
      }
      a:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    .question-card {
      position: relative;
      background: #fff;
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
      border-radius: 0.25rem;
      border: 1px solid rgba(160, 165, 184, 0.4);
      transition: 0.3s;

      .img-wrapper {
        width: 100%;
        height: 11.5625rem;
        background: #ddd;
        position: relative;
        overflow: hidden;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          background-size: 30% auto;
          background-color: rgba(0, 0, 0, 0.1);
          background-image: url("data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%20282.69%20228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20fill%3D%22%23A5A5A5%22%20cx%3D%22115.3%22%20cy%3D%2235.75%22%20r%3D%2235.75%22%2F%3E%3Cpath%20fill%3D%22%23A5A5A5%22%20d%3D%22M188.7%2C228h-81.34c-10.27%2C0-16.24-11.86-10.28-20.41l38.69-55.48l42.65-61.2%20c5.03-7.22%2C15.53-7.22%2C20.56%2C0l42.64%2C61.17l38.7%2C55.51c5.96%2C8.55-0.02%2C20.4-10.28%2C20.4H188.7z%22%2F%3E%3Cpath%20fill%3D%22%23A5A5A5%22%20d%3D%22M2.48%2C206.79l55.44-78.81c4.27-6.07%2C12.64-7.54%2C18.72-3.29l112.83%2C78.81%20c10.8%2C7.54%2C5.46%2C24.51-7.71%2C24.51l-168.27%2C0C2.58%2C228-3.8%2C215.71%2C2.48%2C206.79z%22%2F%3E%3C%2Fsvg%3E");
          background-repeat: no-repeat;
          background-position: 50%;
        }
      }

      .tag {
        position: absolute;
        top: 15px;
        right: 15px;
        display: inline-block;
        color: #333;
        background-color: #f6f6f6;
        font-size: 0.875rem;
        font-weight: 400;
        padding: 4px 8px;
        text-align: center;
        vertical-align: middle;
        border-radius: 4px;
        text-transform: uppercase;
        white-space: nowrap;
      }

      p {
        text-align: center;
        padding: 0.65rem;
        color: #a0a5bb;
        font-size: 1.05rem;
        margin: 0;
      }

      h6 {
        text-align: center;
        padding: 0.65rem;
        color: #545454;
        font-size: 1.25rem;
        font-family: $font-medium;
        margin: 0 auto;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80%;
        cursor: pointer;
      }

      .more {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        z-index: 1;
      }

      .add {
        cursor: pointer;
        height: 17.8125rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h6 {
          padding-top: 1.5rem;
        }
      }
    }

    .question-card:hover {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .question-modules {
    .search-block {
      &-search {
        display: flex;
        align-items: center;
        margin-right: 1.5rem;
      }
    }
  }
}
