@import "./variables.scss";
@import "./pages/modals.scss";
@import "~bootstrap/scss/bootstrap.scss";

@import "./fonts";

@import "./components/input";
@import "./components/buttons";
@import "./components/sidebar";
@import "./components/calendar";
@import "./components/appointment.scss";
@import "./components/navigations";
@import "./components/icons";
@import "./components/search-input";
@import "./components/tabs";
@import "./components/table";
@import "./components/badges";
@import "./components/modal";
@import "./components/loader";
@import "./components/crf.scss";
@import "./components/therapist-slot-modal.scss";

@import "./pages/users/users.scss";
@import "./pages/education/editLessonPage";
@import "./pages/education/editSection";
@import "./pages/education/education";
@import "./pages/education/listPages";
@import "./pages/education/newSection";
@import "./pages/education/previewSection";
@import "./pages/education/questionPreview";

@import "./pages/questions/addQuestions";
@import "./pages/questions/questionBuilder";
@import "./pages/questions/questionModule";
@import "./pages/questions/questionPreview";
@import "./pages/questions/questionResponse";
@import "./pages/questions/questions";
@import "./pages/questions/textChoice";

@import "./pages/scores/createScore";
@import "./pages/scores/scorePreview";
@import "./pages/scores/scores";
@import "./pages/client-list";
@import "./pages/chat";
@import "./pages/dashboard.scss";
@import "./pages/forum.scss";
@import "./pages/document.scss";
@import "./pages/card.scss";

@import "./pages/auth-layout";

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "poppinsregular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.bg-primary-blue {
  background-color: $primary-color;
}
.font-l {
  font-size: 1.5rem;
}
.font-xs {
  font-size: 0.75rem;
}
.font-s {
  font-size: 0.875rem;
}
.font-sm {
  font-size: 0.875rem;
}
.font-m {
  font-size: 1rem;
}
.font-ml {
  font-size: 1.25rem;
}
.fw-semibold {
  font-family: "poppinssemibold";
}
.fw-bold {
  font-family: "poppinsbold";
}
.fw-medium {
  font-family: "poppinsmedium";
}
.input-error {
  color: $red-color;
  font-size: 0.8125rem;
  font-family: "poppinsmedium";
}
.spaced-error {
  margin-top: 5px;
  min-height: 20px;
}
a.link-primary {
  color: $primary-color;
  &:hover {
    color: $primary-color;
  }
}
a.link-normal {
  color: #373737;
  &:hover {
    color: $primary-color;
  }
}
.no-underline {
  text-decoration: none;
}
.mw-400 {
  max-width: 400px;
}

.content.right-panel {
  margin-top: $navbar-height;
  padding: 3.5rem 1.5rem;
  @media (min-width: 768px) {
    padding: 2.5rem;
  }
}

.mb-l {
  margin-bottom: 40px;
}
/*style for scrollbar  */
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}
body,
* {
  box-sizing: border-box;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(11, 11, 11, 0.1);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-light {
  border-color: $color-light-white;
}
.singer-layout {
  .ant-layout {
    background-color: $bg-background;
    min-height: 100vh;
    margin-left: 0;
    @media (min-width: 767px) {
      margin-left: 90px;
    }
  }
}

#custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

#custom-scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

#custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}
.cp {
  cursor: pointer;
}
.theme-white {
  &.singer-layout .ant-layout {
    background-color: $white;
  }
}
.card-holder {
  text-decoration: none;
  color: inherit;
}
.upload-wrapper {
  .upload-wrapper .ant-input {
    background-color: $color-gray-3;
  }
}

.input-control-bar .progressbar {
  li {
    display: inline-block;
  }
  li.active {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: #999999;
    cursor: pointer;
  }
  li.active:hover {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: #000;
    cursor: pointer;
    // text-shadow: 10px 10px 10px #000000;
  }

  li.active:before {
    content: "";
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    background-color: #000;
  }
  li.active::before {
    content: "";
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    background-color: #000;
  }
  // li:before:hover {
  //   content: "";
  //   width: 30px;
  //   height: 30px;
  //   border: 1px solid #ddd;
  //   display: block;
  //   text-align: center;
  //   margin: 0px auto 10px auto;
  //   border-radius: 50%;
  //   background-color: #000;
  //   box-shadow: 0 6px 20px 0 black, 0 6px 20px 0 black;
  // }

  li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #000;
    top: 15px;
    left: -50%;
  }

  li.active:first-child:after {
    content: none;
  }
  li.activated {
    list-style-type: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    color: #000;
  }
  li.activated:before {
    content: "";
    width: 30px;
    height: 30px;
    border: 3px solid #ffffff;
    display: block;
    text-align: center;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    background-color: #000000;
    color: #000000;
    box-shadow: 0 6px 20px 0 black, 0 6px 20px 0 black;
  }

  li.activated:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #000;
    top: 15px;
    left: -50%;
  }

  li.activated:first-child:after {
    content: none;
  }
}
.form-ant-picker {
  padding: 0px 10px;
  input {
    border: none;
    font-size: 1.125rem;
    height: 2.25rem;
  }
}
.shadow-s {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}
.color-primary {
  color: $primary-color !important;
}
.shadow-lg {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16) !important;
}
.cp {
  cursor: pointer;
}
.edit-profile {
  max-width: 700px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(135 136 249 / 40%);
  border-radius: 4px;
}
.disabled-div-qb {
  pointer-events: none;
}
.ant-spin {
  color: $primary-color;
  .ant-spin-dot-item {
    background-color: $primary-color;
  }
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: text-bottom;
}
a {
  color: $primary-color;
  text-decoration: none;
}
a:hover {
  color: $primary-color;
}

.styled-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 4px;
    // border: 4px solid transparent;
  }
}
.img-responsive {
  width: 100%;
}
