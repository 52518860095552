.preview-section-wrapper {
  // margin-left: 15.625rem;
  flex: 1;
  background: #e5e5e5;
  min-height: 100vh;
  padding: 150px 0;

  .preview-section-container {
    max-width: 770px;
    // height: 44.875rem;
    // overflow-y: auto;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-bottom-color: transparent;
    box-sizing: border-box;
    margin: 0 auto;

    .preview-header {
      text-align: center;
      margin: 0rem 3.75rem;
      margin-top: 2rem;
      padding: 1rem 0;

      &-lesson {
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        letter-spacing: 1.6px;
        color: #383838;
      }

      &-pages {
        font-style: normal;
        font-weight: 500;
        font-size: 0.9375rem;
        margin-top: 0.6rem;
        color: #383838;
      }
    }

    .section-header {
      text-align: center;
      padding: 1rem 0;

      h2 {
        margin: 0px;
        position: relative;
        padding-bottom: 2rem;
      }

      h2:after {
        background: #30d073;
        position: absolute;
        bottom: 0;
        left: 35%;
        height: 5px;
        width: 17rem;
        @media (min-width: 991px) {
          content: "";
        }
      }

      &-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 0.9375rem;
        color: #383838;
        @media (min-width: 991px) {
          padding: 0rem 7rem;
        }
      }
    }

    .section-photo {
      height: 100%;
      width: 100%;
      // position: relative;
      overflow: hidden;
      img {
        // position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .section-video {
      height: 40vh;
      width: 100%;
      padding: 5px;
      position: relative;

      .education-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 1s;
        z-index: 1;
        border: 1px solid black;
      }
    }
    .section-description {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0;

      .description-header {
        &-title {
          font-style: normal;
          font-weight: 500;
          font-size: 1.875rem;
          color: #383838;
        }

        &-line {
          width: 13.875rem;
          height: 0.125rem;
          background: #30d073;
          margin: 2.5rem auto;
        }

        &-subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          color: #767676;
        }
      }

      .description-body {
        text-align: center;

        @media (min-width: 992px) {
          margin: 1.25rem 7.1875rem;
        }

        .quill {
          .ql-container {
            font-size: 1.25rem;
            margin: 1rem 0rem;
            color: #4f4f4f;
            .ql-editor {
              line-height: 2rem;
            }
            .ql-tooltip {
              display: none;
            }
          }
        }
      }
    }

    .section-resources {
      margin: 1.25rem 7.1875rem;
      margin-bottom: 1.25rem;
      padding: 0px 0.9375rem;
      .resource-title {
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        color: #383838;
      }
      a {
        word-break: break-all;
      }

      a:hover {
        border-bottom: 1px solid #1890ff;
        text-decoration: none;
      }
    }
  }

  .section-question {
    margin: 0 7.1875rem;
    padding: 0 0.9375rem;
    .render-question {
      margin: 1.25rem 0;

      .checkbox-input {
        p {
          margin: 0 0.625rem;
        }
      }
    }
  }

  .preview-section-pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 770px;
    background: #f2f5f7;
    margin: 0 auto;
    border: 1px solid #dddddd;
    border-top-color: transparent;
    padding: 50px 0;

    > span {
      margin: 0.9375rem 0;
    }

    .ant-pagination {
      .ant-pagination-next {
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: $primary-color;
        }

        .add-web-finish-line {
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          cursor: not-allowed;
        }
      }

      .ant-pagination-item {
        background: #c3cfd9;
        a {
          color: white;
        }
      }

      .ant-pagination-item-active {
        background: $primary-color;
        a {
          color: white;
        }
      }
    }
  }
}

.preview-section-mobile-wrapper {
  flex: 1;

  .preview-header {
    text-align: center;
    margin: 0rem 0.75rem;
    margin-top: 2rem;
    padding: 1rem 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-lesson {
      font-style: bold;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 1.6px;
      color: black;
      width: 93%;
      // color: #828282;
      // font-size: 0.875rem;
    }

    .bookmark-indicator {
      padding: 1rem 0rem;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .preview-header-pages {
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375rem;
    margin-top: 0.6rem;
    color: #767676;
    width: 93%;
  }

  .preview-section-container {
    background: #ffffff;
    box-sizing: border-box;
    margin-bottom: 0;

    .section-header {
      text-align: center;
      padding-bottom: 1rem;

      h3 {
        margin: 0px;
      }

      h3:after {
        content: "";
        display: block;
        margin: 0 auto;
        padding-bottom: 2rem;
        border-bottom: 5px solid #30d073;
        width: 17rem;
      }

      &-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #828282;
        padding: 1rem 0.5rem;
      }

      h3 {
        margin-top: 2rem;
      }
    }

    .section-photo {
      height: 100%;
      width: 100%;
      padding: 5px;
      // position: relative;
      overflow: hidden;
      img {
        // position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .section-video {
      height: auto;
      width: 100%;
      padding: 5px;
      position: relative;

      .education-video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 1s;
        z-index: 1;
        border: 1px solid black;
      }
    }

    .section-description {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0;

      .description-header {
        &-title {
          font-style: normal;
          font-weight: 500;
          font-size: 1.875rem;
          color: #383838;
        }

        &-line {
          width: 13.875rem;
          height: 0.125rem;
          background: #30d073;
          margin: 2.5rem auto;
        }

        &-subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 0.875rem;
          color: #767676;
        }
      }

      .description-body {
        margin: 1.25rem 7.1875rem;
        text-align: center;

        .quill {
          .ql-container {
            font-size: 1.25rem;
            margin: 1rem 0rem;
            color: #4f4f4f;
            .ql-editor {
              line-height: 2rem;
            }
            .ql-tooltip {
              display: none;
            }
          }
        }
      }
    }

    .section-resources {
      margin: 1.25rem 7.1875rem;
      margin-bottom: 1.25rem;
      padding: 0px 0.9375rem;

      .resource-title {
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        color: #383838;
      }
      a {
        word-break: break-all;
      }
    }
    .section-question {
      padding: 0 0.9375rem;
      position: relative;

      .render-question {
        margin: 1.25rem 0;

        &-header {
          color: #17a2b8;
          font-weight: bold;
          font-size: 1.1rem;
        }

        .input-container {
          margin-bottom: 2rem;

          .input-header {
            font-size: 1.2rem;
            color: black;
            margin-bottom: 1rem;
          }

          .input-control {
            .ant-input {
              font-size: 1.1rem;
              padding: 9px 8px;
              margin-bottom: 4px;
              display: block;
              width: 100%;
              background-color: transparent;
              border: 0;
              border-bottom: 1px solid #ccc;
            }
          }

          .input-check {
            .checked-container {
              margin-bottom: 1rem;
              .ant-checkbox-wrapper {
                display: flex;
                align-items: center;

                .ant-checkbox {
                  .ant-checkbox-inner {
                    width: 1.25rem;
                    height: 1.25rem;
                    background-color: #fff;
                    border: 2px solid #979797;
                  }
                }

                .ant-checkbox-checked .ant-checkbox-inner {
                  border: 2px solid #ff6767;
                }

                .ant-checkbox-checked .ant-checkbox-inner:after {
                  border: 2px solid #ff6767;
                  border-top: 0;
                  border-left: 0;
                  margin-left: 0.5px;
                }

                .ant-checkbox-checked::after {
                  border: none;
                }

                span:nth-child(2) {
                  padding-right: 8px;
                  padding-left: 8px;
                  font-size: 1.1rem;
                  color: #4f4f4f;
                }
              }
            }
          }

          .input-radio {
            .ant-radio-group {
              .radio-container {
                margin-bottom: 1rem;
                .ant-radio-wrapper {
                  display: flex;
                  align-items: center;

                  .ant-radio {
                    .ant-radio-inner {
                      width: 1.25rem;
                      height: 1.25rem;
                      background-color: #fff;
                      border: 2px solid #979797;
                    }
                  }

                  .ant-radio-checked .ant-radio-inner {
                    border: 2px solid #ff6767;
                  }

                  .ant-radio-checked .ant-radio-inner:after {
                    border: 2px solid #ff6767;
                    background-color: #ff6767;
                    border-top: 0;
                    border-left: 0;
                    margin-left: 0.5px;
                    margin-top: 1px;
                  }

                  .ant-radio-checked::after {
                    border: none;
                  }

                  span:nth-child(2) {
                    padding-right: 8px;
                    font-size: 1.1rem;
                    color: #4f4f4f;
                  }
                }
              }
            }
          }
        }

        .del-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          transition: 0.3s ease;
          background: rgba(0, 0, 0, 0.7);
          z-index: 2;

          &-options {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;
            z-index: 3;
          }
          &-header {
            font-style: normal;
            font-weight: 500;
            font-size: 2.5rem;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
          }
          &-text {
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }

  .preview-section-pagination {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f2f5f7;
    margin: 0 auto;
    border: 0.3px solid #dddddd;
    margin: 0rem 3.75rem;
    margin-bottom: 1.875rem;
    > span {
      margin: 0.9375rem 0;
    }

    .ant-pagination {
      .ant-pagination-next {
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: $primary-color;
        }

        .add-finish-line {
          font-style: normal;
          font-weight: 500;
          font-size: 1.1rem;
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          cursor: not-allowed;
        }
      }

      .ant-pagination-prev {
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 1.125rem;
          color: $primary-color;
        }
      }

      .ant-pagination-item {
        background: #c3cfd9;
        a {
          color: white;
        }
      }

      .ant-pagination-item-active {
        background: $primary-color;
        a {
          color: white;
        }
      }
    }
  }
}

.preview-no-page-content {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    font-size: 1rem;
    color: #545454;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .preview-section-mobile-wrapper {
    width: 100vw;
    .preview-section-container {
      width: 100vw;

      .section-description {
        .description-body {
          margin: 0rem;
        }
      }
      .section-resources {
        margin: 1rem 0rem;
      }
    }

    .preview-section-pagination {
      background: #ffffff;
      margin: 1rem 0rem;
      padding: 2rem 0rem;
      .ant-pagination {
        .ant-pagination-next {
          a {
            font-style: normal;
            font-weight: 500;
            font-size: 1.1rem;
            color: $primary-color;
            margin: 0px auto;
          }

          .add-finish-line {
            font-style: normal;
            font-weight: 500;
            font-size: 1.1rem;
            color: rgba(0, 0, 0, 0.25);
            border-color: #d9d9d9;
            cursor: not-allowed;
          }
        }

        .ant-pagination-item {
          display: none;
        }
      }
    }

    .page-number {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem auto;
      margin: 1rem;
      color: #4f4f4f;
    }
  }
}

.add-prev-line {
  padding-right: 5rem;
}

.add-next-line {
  padding-left: 5rem;
}

.add-finish-line {
  padding-left: 80px;
}

.completion-lesson {
  margin-top: 2rem;
  text-align: center;

  h4 {
    margin-top: 1rem;
  }

  &-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #828282;
  }
}

@media only screen and (max-width: 1024px) {
  .preview-section-wrapper {
    // margin-left: 5rem;
    min-height: 130vh;
    .preview-section-container {
      max-width: 50.8135rem;
    }
    .preview-section-pagination {
      // width: 50.8135rem;
    }
  }
}

.ques-survery-background {
  background: #ff6767 !important;
}
.ques-survery-background-disabled {
  background: #cea1a1 !important;
}
.ntu-button {
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 0.25rem;
  height: 40px;
  font-size: 0.875rem;
  padding: 4px 0.625rem;
  min-width: 6rem;
  font-weight: 400;
  line-height: 2.1875rem;
  span {
    line-height: 20px;
  }
}
