.choice-field {
  background: #f8f9fb;
  padding: 1.25rem;
  border: 1px solid #d8d8d8;
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;

  .field-header {
    display: flex;
    padding: 2px 3px;
    align-items: center;
    border-radius: $border-m;

    > span {
      margin: 0px 12px;
      font-size: 0.875rem;
      color: #ffffff;
    }
  }

  .qb-floating-label {
    width: 88%;
    .ant-input {
      font-size: 1rem;
      border: 0;
      background-color: #f8f9fb !important;
      color: #333333;
      padding: 0px 20px;
      padding-top: 5px;
      font-family: $font-medium;
    }
    .ant-input:placeholder-shown {
      font-size: 1.3rem;
    }
    .ant-input::placeholder {
      color: #333333;
    }
    .ant-input:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .field-header > * {
    vertical-align: middle;
  }

  p {
    color: #aba5b8;
    font-size: 0.875rem;
    font-weight: bold;
  }

  .field-group {
    margin-bottom: 0.5rem;

    .checkboxes {
      display: flex;
      align-items: center;

      .circle {
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border: 1px solid #aba8b8;
        margin-right: 0.5rem;
      }

      .first-input {
        margin-right: 0.5rem;
        flex: 1;
      }

      .second-input {
        width: 4rem;
      }

      .image-label-input {
        width: 50%;
      }
    }

    .ant-slider {
      width: 50%;

      &-rail {
        background-color: #bdc3c7;
      }
    }
  }

  .options {
    display: flex;
    align-items: center;
    width: 50%;
    .max {
      display: flex;
      align-items: center;

      span {
        color: #aba8b8;
        font-size: 0.875rem;
        margin-right: 0.5rem;
      }

      .icons-wrapper {
        display: flex;
        flex-direction: column;
      }

      .ant-input {
        width: 5rem;
        font-size: 0.875rem;
      }
    }
  }

  .questionaire-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: 0.875rem;

    .left-actions {
      display: flex;
      align-items: center;

      .attachment,
      .required {
        display: flex;
        align-items: center;
      }

      .attachment {
        margin-right: 0.75rem;
      }

      .required {
        margin-right: 0.75rem;

        &-check {
          margin-right: 0.5rem;

          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #fbb040;
            border-color: #fbb040;
          }

          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #d8d8d8;
          }

          .ant-checkbox-wrapper:hover .ant-checkbox-inner,
          .ant-checkbox:hover .ant-checkbox-inner {
            border-color: #fbb040;
          }

          .ant-checkbox-checked:after {
            border-color: #d8d8d8;
          }
        }
      }
    }

    .right-actions {
      display: flex;
      align-items: center;

      .more-icon {
        transform: rotate(90deg);
      }
    }
  }

  .question-group {
    .add-question {
      color: #56ccf2;
      text-align: right;
    }
  }
}

.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .choice-field {
    padding: 1rem;
  }
}
