.client-view-wrapper {
  background-color: #f8f9fb;
}
.clientlist-table {
  .ant-table-content {
    font-family: $font-medium;
    p {
      margin: 0;
    }
    td {
      padding: 0;
      color: #4f4f4f;
      height: 60px;
      .td-wrapper {
        height: 60px;
        display: flex;
        flex-direction: column;
      }
      .healthy-range {
        background-color: $healthy-color-faded;
        color: $healthy-color;
      }

      .unhealthy-range {
        background-color: $unhealthy-color-faded;
        color: $unhealthy-color;
      }

      .moderate-health-range {
        background-color: $average-orange-faded-color;
        color: $average-orange-color;
      }

      .unknown-health-range {
        color: $unknown-range-color;
        background-color: $unknown-range-color-faded;
      }

      .mahalo-dashboard-cell-section {
        //   @include flex-centered;
        // height: 32px;
        flex: 1;
        min-width: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
.clientlist-table {
  .ant-table-thead {
    th {
      padding: 0px;
      background: #fff;
      width: 5%;
    }
    .client-table-header {
      height: 80px;
      padding: 5px;
      .graph-text {
        font-family: "poppinssemibold";
        font-size: 12px;
        line-height: 16px;
        color: #333333;
      }
    }
    .client-table-header.active-graph {
      .graph-text {
        color: #ffffff;
      }
    }
    .client-table-header.graph-item {
      cursor: pointer;
    }
  }
}
.clientlist-table.individual-table {
  .ant-table-thead {
    th {
      padding: 0px;
      background: #fff;
      width: 6.66%;
    }
  }
}
.clientlist-table {
  table td {
    background-color: $white;
  }
  .ant-table-thead {
    // tr > th {
    //   background: #fff;
    //   // border-bottom: none;
    //   // padding: 0;
    //   > div {
    //     border-left: 1px solid #e0e0e0;
    //     padding: 20px;
    //     // margin-bottom: 10px;
    //   }
    //   svg {
    //     min-height: 25px;
    //   }
    // }
  }
  // tr > td {
  //   border-left: 1px solid #e0e0e0;
  // }
  // table {
  //   border-collapse: unset;
  // }
}
.dashboard-title {
  font-family: $font-regular;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.filter-data {
  display: flex;
  align-items: center;
  li {
    cursor: pointer;
    margin: 0 0.5rem;
  }
  .active {
    background-color: $primary-transparant;
    color: $primary-color;
    padding: 0.5rem 0.625rem;
    border-radius: 5px;
  }
}

.personalDetails h6 {
  white-space: nowrap;
  font-size: 1rem;
}
.personalDetails {
  height: 110px;
  overflow: auto;
  flex-flow: nowrap;
  .detail-cell {
    border-right: 1px solid #e0e0e0;
    .email-label {
      font-size: 14px;
      line-height: 18px;
      align-items: center;
      color: #4f4f4f;
    }
    .name-label {
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: #333333;
    }
    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #828282;
    }
    .value {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
  }
}
.personalDetails > div {
  white-space: nowrap;
  padding: 0px 20px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: auto;
}
li#listItem {
  display: inline-table;
  line-height: normal;
  // margin-left: 50px;
}
.active-graph {
  background-color: $primary-color;
  .fill-path {
    fill: #ffffff;
  }
  .fill-path-stroke {
    fill: #ffffff;
    stroke: #ffffff;
  }
}
.patient-chat-holder {
  height: 75vh;
  display: flex;
  flex-direction: column;
}
.entry-container {
  max-height: 50vh;
  overflow: auto;
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 4px;
    border: 1px solid #fff;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
  .entries-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
  }
  .entries-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
  }
}

.medicine-detail {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #333333;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-width: 200px;
  width: fit-content;
  padding: 15px;
  &::before {
    content: "";
    width: 4px;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: $primary-color;
  }
}
