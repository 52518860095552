.singer-custom-table {
  // box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);
  // padding: 2px;
  .ant-table {
    box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);
    border-radius: $border-l;
    margin-bottom: 40px;

    .ant-table-thead {
      > tr > th,
      .ant-table-tbody > tr > td,
      .ant-table tfoot > tr > th,
      .ant-table tfoot > tr > td {
        padding: 0.7rem 1rem;
      }
      tr:first-child th:first-child {
        border-top-left-radius: $border-l;
      }
      tr:first-child th:last-child {
        border-top-right-radius: $border-l;
      }
      th {
        background: $bg-thead;
        white-space: nowrap;
        font-size: 0.875rem;
      }
      .ant-table-cell {
        color: $color-gray1;
        font-weight: 600;
      }
    }

    .ant-table-tbody {
      tr {
        td {
          font-style: normal;
          font-weight: normal;
          color: $table-body;
          font-size: 0.875rem;
          line-height: 1.315rem;
          font-weight: 400;
          // font-family: "poppinsmedium";
          // background-color: $white;
        }
      }
    }

    // .ant-table-cell:nth-child(6) {
    //   text-align: right;
    // }
  }
}
.singer-custom-table,
.clientlist-table {
  .ant-pagination {
    li {
      height: 40px;
      min-width: 40px;
      line-height: 38px;
      box-shadow: 1px 2px #efefef;
      border-left: solid 1px #efefef;
    }
    .ant-pagination-item {
    }
  }

  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-next,
  .ant-pagination-item {
    margin: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
    font-size: 0.875rem;
    margin-left: -1px;
  }

  .ant-pagination-item-active,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    border: 1px solid #d9d9d9;
    border-left: 0;
    color: $color-orange;
  }
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #d9d9d9;
    color: $color-orange;
  }

  .ant-pagination-disabled span {
    opacity: 0.4;
  }
  .ant-pagination .ant-pagination-total-text {
    font-size: 0.875rem;
    order: 2;
    margin-left: 1.25rem;
    box-shadow: none;
    border-left: 0;
    line-height: 2.5rem;
  }
  .ant-pagination-next .ant-pagination-item-link {
    border-left: 0;
  }
  .ant-pagination-next,
  .ant-pagination-prev {
    .ant-pagination-item-link {
      span {
        width: 9px;
        height: 13px;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yLjgyOCA2Ljk5OTk5TDcuNzc4IDExLjk1TDYuMzY0IDEzLjM2NEwwIDYuOTk5OTlMNi4zNjQgMC42MzU5ODZMNy43NzggMi4wNDk5OUwyLjgyOCA2Ljk5OTk5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
      }
      svg {
        display: none;
      }
    }
  }
  .ant-pagination-next .ant-pagination-item-link {
    span {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjE3MTY4IDYuOTk5OTlMMC4yMjE2OCAyLjA0OTk5TDEuNjM1NjggMC42MzU5ODZMNy45OTk2OCA2Ljk5OTk5TDEuNjM1NjggMTMuMzY0TDAuMjIxNjggMTEuOTVMNS4xNzE2OCA2Ljk5OTk5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==);
    }
  }
}

.dashboard-table {
  .ant-table-thead {
    th {
      background: #fff;
    }
  }
}
.dashboard-table-view {
  .ant-table-thead {
    tr > th {
      background: #fff;
      border-bottom: none;
      padding: 0;
      > div {
        border-left: 1px solid #e0e0e0;
        padding: 20px;
        margin-bottom: 10px;
      }
      svg {
        min-height: 25px;
      }
    }
  }
  tr > td {
    border-left: 1px solid #e0e0e0;
  }
  table {
    border-collapse: unset;
  }
}
