/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 2, 2021 */

@font-face {
  font-family: "poppinsbold";
  src: url("../assets/fonts/poppins-bold-webfont.eot");
  src: url("../assets/fonts/poppins-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/poppins-bold-webfont.woff2") format("woff2"),
    url("../assets/fonts/poppins-bold-webfont.woff") format("woff"),
    url("../assets/fonts/poppins-bold-webfont.ttf") format("truetype"),
    url("../assets/fonts/poppins-bold-webfont.svg#poppinsbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinslight";
  src: url("../assets/fonts/poppins-light-webfont.eot");
  src: url("../assets/fonts/poppins-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/poppins-light-webfont.woff2") format("woff2"),
    url("../assets/fonts/poppins-light-webfont.woff") format("woff"),
    url("../assets/fonts/poppins-light-webfont.ttf") format("truetype"),
    url("../assets/fonts/poppins-light-webfont.svg#poppinslight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsmedium";
  src: url("../assets/fonts/poppins-medium-webfont.eot");
  src: url("../assets/fonts/poppins-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/poppins-medium-webfont.woff2") format("woff2"),
    url("../assets/fonts/poppins-medium-webfont.woff") format("woff"),
    url("../assets/fonts/poppins-medium-webfont.ttf") format("truetype"),
    url("../assets/fonts/poppins-medium-webfont.svg#poppinsmedium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsregular";
  src: url("../assets/fonts/poppins-regular-webfont.eot");
  src: url("../assets/fonts/poppins-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/poppins-regular-webfont.woff2") format("woff2"),
    url("../assets/fonts/poppins-regular-webfont.woff") format("woff"),
    url("../assets/fonts/poppins-regular-webfont.ttf") format("truetype"),
    url("../assets/fonts/poppins-regular-webfont.svg#poppinsregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinssemibold";
  src: url("../assets/fonts/poppins-semibold-webfont.eot");
  src: url("../assets/fonts/poppins-semibold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/poppins-semibold-webfont.woff2") format("woff2"),
    url("../assets/fonts/poppins-semibold-webfont.woff") format("woff"),
    url("../assets/fonts/poppins-semibold-webfont.ttf") format("truetype"),
    url("../assets/fonts/poppins-semibold-webfont.svg#poppinssemibold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
