.dashboard-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #333333;
}
.dashboard-card-wrapper {
  display: grid;
  row-gap: 30px;
  column-gap: 30px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: 30px;
  .dashboard-card {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    height: 160px;
    .dashboard-top {
      border-radius: 12px 12px 0px 0px;
      height: 10px;
    }
    .card-details {
      padding: 15px;
      .dashboard-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #333333;
        margin-bottom: 25px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #333333;
        .ant-progress-status-success {
          .ant-progress-text {
            color: #333333;
          }
        }
      }
    }
  }
}
.questionnaire-card-wrapper {
  display: grid;
  column-gap: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 30px;
  .questionnaire-cards {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    .dashboard-title {
      margin-bottom: 40px;
    }
    .questionnaire-cards-content {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 45px;
      .questionnaire-progress-bar {
        max-width: 50%;
        .ant-progress-bg {
          background-color: $primary-color;
        }
        .ant-progress-text {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #333333;
        }
      }
    }
  }
}
.dashboard-scheduling-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  .scheduling-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .scheduling-period-select {
      margin-right: 25px;
      span {
        cursor: pointer;
        margin-left: 15px;
        font-size: 14px;
        line-height: 16px;
        color: "#333333";
      }
      .active {
        color: $primary-color;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
    }
    .therapist-title {
      margin-right: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #5c5c5c;
    }
    .scheduling-options {
      display: flex;
      align-items: center;
    }
    .therapist-selector {
      width: 200px;
    }
  }
  .graph-wrapper {
    height: 500px;
    position: relative;
    .graph-spinner {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
.other-analytics-wrapper {
  display: grid;
  column-gap: 30px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 30px;
  .other-analytics-card {
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    .dashboard-title {
      margin-bottom: 25px;
    }
    .graph-legend-wrapper {
      min-height: 400px;
      position: relative;
      display: flex;
      .graph-spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7);
      }
      .empty-note {
        min-height: 400px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .donut-graph-wrapper {
        width: 50%;
        height: 400px;
        position: relative;
        .chart-center-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .value {
            font-weight: 600;
            font-size: 22px;
            line-height: 18px;
            color: #333333;
          }
          .label {
            font-size: 14px;
            line-height: 22px;
            color: #4f4f4f;
            margin-bottom: 5px;
          }
        }
      }
      .donut-label-wrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 25px;
        .donut-chart-legend {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .point {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .label {
            font-size: 14px;
            line-height: 22px;
            color: #4f4f4f;
            flex: 1;
            min-width: 0px;
            border-right: 2px solid rgba(0, 0, 0, 0.25);
            margin-right: 15px;
          }
          .value {
            font-size: 14px;
            line-height: 22px;
            color: #4f4f4f;
          }
        }
      }
    }
  }
}
.alert-analytics-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(135, 136, 249, 0.4);
  border-radius: 6px;
  padding: 20px;
}
.program-overview {
  flex: 1;
  min-width: 0px;
  .dashboard-wrapper {
    .overview-stats {
      padding: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      .stat-container {
        .stat-value {
          font-size: 24px;
          line-height: 18px;
          color: #383838;
        }
        .stat-label {
          font-size: 12px;
          line-height: 18px;
          color: #767676;
        }
      }
    }
    .participants {
      margin-top: 20px;
      padding: 15px;
      .participants-header {
        font-weight: 500;
        padding-bottom: 20px;
        font-size: 18px;
        line-height: 18px;
        color: #383838;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      .gender-icons {
        width: 50%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        .label {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #000000;
          margin-right: 10px;
        }
        .value {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #a0a5b8;
        }
      }
      .particpant-chart-container {
        height: 300px;
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chart-wrapper {
          height: 300px;
          width: 50%;
          position: relative;
          .chart-center-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            .value {
              font-size: 24px;
              line-height: 18px;
              color: #000000;
              margin-bottom: 5px;
            }
            .label {
              font-size: 12px;
              line-height: 18px;
              color: #a0a5b8;
            }
          }
        }
        .donut-chart-legend {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          .point {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 5px;
          }
          .label {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
            margin-right: 10px;
          }
          .value {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #a0a5b8;
          }
        }
      }
    }
    .questionnaire-graph {
      // padding: 20px;
      height: 400px;
    }
  }
}
.dashboard-question-wrapper {
  display: flex;
}
.question-response-wrapper {
  background: #f8f9fb;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 60px 30px 20px 30px;
  width: 25%;
  margin-left: 38px;
  margin-right: -2.5rem;
  border-radius: 8px;
  height: 80vh;
  overflow: auto;
  .question-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 18px;
    color: #a0a5b8;
    margin-bottom: 58px;
  }
  .answer-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 18px;
    color: #383838;
    margin-bottom: 58px;
  }
}
.question-response-container {
  .question-response-card {
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(135, 136, 249, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    .question-icon {
      margin-right: 10px;
    }
    .question {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #383838;
    }
    .responses {
      font-weight: normal;
      font-size: 8px;
      line-height: 18px;
      color: #a0a5b8;
    }
    .option-wrapper {
      margin-top: 30px;
      .options {
        font-size: 12px;
        line-height: 18px;
        color: #383838;
      }
      .option-percentage {
        font-size: 12px;
        line-height: 18px;
        color: #a0a5b8;
      }
    }
  }
  .empty-questions {
    font-weight: 500;
    font-size: 22px;
    color: #a0a5b8;
    text-align: center;
  }
}
.modal-question-view {
  max-height: 60vh;
  overflow: auto;
  padding: 15px;
  .question-response-card {
    .question {
      font-size: 16px;
      font-weight: bold;
    }
    .option-wrapper {
      margin-top: 20px;
      .options {
        font-weight: 500;
        font-size: 14px;
        color: #383838;
        display: flex;
        align-items: center;
        .questions-image {
          margin-bottom: 10px;
          margin-right: 10px;
          img {
            width: 100px;
            height: 100px;
          }
        }
      }
      .selected-option {
        font-weight: bold;
      }
    }
  }
}
.questionnaire-link {
  cursor: pointer;
  color: $primary-color;
}
