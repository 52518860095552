.therapist-slot-wrapper {
  .ant-tabs-tab-btn {
    font-family: $font-medium;
  }
  .ant-modal-body {
    padding: 0px;
    .ant-tabs-nav {
      margin-top: 50px;
    }
    .therapist-slot-tab {
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          flex: 1;
          min-width: 0px;
          justify-content: center;
        }
        .ant-tabs-ink-bar-animated {
          width: 50% !important;
        }
      }
      .ant-tabs-content {
        padding: 1.875rem;
        // padding: 20px 40px;
      }
    }
  }
}
