.calendar-view-wrapper {
  .calendar-cards {
    // display: flex;
    // align-content: flex-start;
    // justify-content: space-between;
    .calendar-card {
      background: #ffffff;
      border-radius: 4px;
      padding: 30px 20px;
      min-width: 45%;
      height: 100%;
    }

    .header {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #333333;
      margin-bottom: 50px;
    }
    .react-calendar {
      border: none;
      width: 100%;
      //aligning the month Label
      .react-calendar__navigation__label {
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #333333;
        background-color: #ffffff;
        order: 1;
        text-align: left;
      }
      //Disabling Year navigation arrows
      .react-calendar__navigation__next2-button,
      .react-calendar__navigation__prev2-button {
        display: none;
      }
      //Stying and ordering navigation arrows
      .react-calendar__navigation__arrow {
        background-color: #ffffff;
        order: 2;
        font-weight: bold;
        font-size: 24px;
        color: #333333;
      }
      .react-calendar__navigation__next-button {
        margin-right: calc(14.2857% - 60px);
      }
      //Styling disabled navigation arrow
      .react-calendar__navigation__arrow:disabled {
        color: #e0e0e0;
        cursor: not-allowed;
      }
      //Styling Week header
      .react-calendar__month-view__weekdays__weekday {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #333333;
        cursor: pointer;
        text-align: left;
        padding-left: 15px;
        text-transform: none;
        abbr {
          width: 40px;
          height: 40px;
          text-decoration: none;
          cursor: pointer;
          text-align: left;
        }
      }
      //Current day stying
      .react-calendar__tile--now {
        background-color: #ffffff;
        color: $primary-color;
      }
      //Calendar day styles
      .react-calendar__month-view__days__day {
        //Text content inside the calendar
        background-color: #ffffff;
        abbr {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          line-height: 27px;
          color: $color-gray;
          border-radius: 50%;
          text-align: center;
        }
      }
      .react-calendar__month-view__days__day:disabled {
        background-color: #ffffff;
        abbr {
          border-color: #e0e0e0;
          color: #e0e0e0;
          cursor: not-allowed;
        }
      }
      //Weekends have red color as default, overriding
      .react-calendar__month-view__days__day--weekend {
        abbr {
          color: $color-gray;
        }
      }
      //Selected Day styles
      .react-calendar__tile--active {
        background-color: #ffffff;
        abbr {
          background-color: $primary-color;
          color: #ffffff;
        }
      }
      //Hover and focus for unselected cell
      .react-calendar__tile:enabled:hover,
      .react-calendar__tile:enabled:focus {
        background-color: #ffffff;
      }
    }
    .slot-container {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .slot-item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 40px;
        width: 120px;
        margin-bottom: 20px;
        margin-right: 10px;
        border: 2px solid $green-color;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $green-color;
        padding: 11px 18px;
      }
      .slot-item.busy {
        color: #eb5757;
        border: 2px solid #eb5757;
      }
      .slot-item.scheduled {
        color: $primary-color;
        border: 2px solid $primary-color;
        cursor: auto;
      }
      .slot-item.unavailable {
        color: #bdbdbd;
        border: 2px solid #bdbdbd;
        cursor: auto;
      }
    }
  }
}
