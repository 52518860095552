.edit-section-wrapper {
  background: #fff;
  // margin-left: 15.625rem;
  padding: 3.75rem 4.5rem;
  flex: 1;

  .button-save {
    width: 8.4375rem;
  }

  .button-cancel {
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    text-decoration-line: underline;
    color: #767676;
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 1.0675rem;
    color: #545454;
  }

  .text-editor {
    margin-top: 5.625rem;
  }

  .quill {
    .ql-toolbar {
      .ql-formats {
        padding: 1rem 0px;
        padding-right: 3.125rem;
      }
    }
    .ql-container {
      height: 50vh;
      font-size: 1.125rem;
    }
  }


}
