.create-score {
  min-height: 100vh;
  overflow-x: hidden;

  .score-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    .input-name {
      .ant-input {
        background-color: #fff;
        border: 0;
        padding: 0.75rem;
        border-radius: 0.25rem;
        box-shadow: 0 1px 0.25rem rgb(0 0 0 / 16%);
        outline: none;
      }
    }
  }

  .score-main {
    .score-questions-list {
      background: rgba(196, 196, 196, 0.14);
      .score-questions-header {
        background: #ffffff;
        border: 1px solid rgba(160, 165, 184, 0.4);
        box-sizing: border-box;
        border-radius: 0.125rem;
        font-style: normal;
        font-weight: 500;
        font-size: 0.95rem;
        padding: 0.75rem;
        color: #333333;
      }
      .score-questions-item {
        overflow-y: auto;
        height: 35rem;
        .score-question-content {
          background: #ffffff;
          border: 1px solid rgba(160, 165, 184, 0.4);
          box-sizing: border-box;
          border-radius: 0.125rem;
          font-style: normal;
          font-weight: 500;
          font-size: 0.9375rem;
          padding: 0.85rem;
          margin: 0.5rem;
          color: #000000;
          .question-number {
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            color: #545454;
          }
          .question-title {
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            color: #000000;
          }
        }
      }

      .score-questions-item:-webkit-scrollbar {
        width: 5px;
      }

      .score-questions-item::-webkit-scrollbar-thumb {
        background: #ccced4;
        border-radius: 5px;
      }

      .score-question-content:hover {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      }
    }

    .score-calculator {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      padding-bottom: 1rem;

      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        color: #545454;
        margin-bottom: 40px;
        margin-top: 20px;
      }

      .score-box {
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 3rem 1rem;
        // margin: 0rem 1.4rem;

        .score-x {
          margin: 0;
          font-style: normal;
          font-weight: bold;
          font-size: 1.1rem;
          color: #545454;
        }

        .question-input {
          border: none;
          background: transparent;
          outline: none;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: #30d073;
          width: 43px;
          text-align: center;
        }
        .operator-input {
          border: none;
          background: transparent;
          outline: none;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          width: 16px;
          color: #eb5757;
          text-align: center;
        }
        .const-input {
          border: none;
          background: transparent;
          outline: none;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          width: 3rem;
          color: #333333;
          text-align: center;
        }
      }

      .score-calc-buttons {
        margin: 1rem;
        .score-calc-item {
          box-sizing: border-box;
          border-radius: 4px;
          text-align: center;
          padding: 1rem 0rem;
          // width: 23%;
          font-weight: bold;
          font-size: 1.1rem;
          font-style: normal;
          color: #ffffff;
        }

        .score-calc-item:hover {
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }

    .score-validation {
      background: #ffffff;

      .score-validation-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #e0e0e0;

        h5 {
          font-size: 1rem;
          color: #000000;
        }
      }

      .score-validation-body {
        padding: 1rem;
        .score-conditions {
          display: flex;
          align-items: center;

          > span {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            color: #333333;
          }

          .floating-select {
            .ant-select {
              .ant-select-selector {
                width: 16rem;
                height: 2.5rem;
                padding: 4px 1rem;
                .ant-select-selection-item {
                  font-size: 1.05rem;
                  margin-right: 5px;
                  font-style: normal;
                  font-weight: 500;
                  color: #000000;
                  .ant-select-selection-item-content {
                  }
                  .ant-select-selection-item-remove {
                    margin: -3px 0px;
                    margin-bottom: 4px;
                  }
                }
              }
              .ant-select-arrow {
                color: #383838;
              }
            }
          }

          .floating-between {
            .ant-select {
              .ant-select-selector {
                width: 14rem;
                height: 2.5rem;
                padding: 4px 1rem;
                .ant-select-selection-item {
                  font-size: 1.05rem;
                  margin-right: 5px;
                  font-style: normal;
                  font-weight: 500;
                  color: #000000;
                  .ant-select-selection-item-content {
                  }
                  .ant-select-selection-item-remove {
                    margin: -3px 0px;
                    margin-bottom: 4px;
                  }
                }
              }
              .ant-select-arrow {
                color: #383838;
              }
            }
          }

          .between-inputs-text {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            color: #333333;
            margin: 0rem 0.3rem;
          }

          .input-name {
            .ant-input {
              background: #ffffff;
              border: 1px solid #dbdbdb;
              box-sizing: border-box;
              border-radius: 0.25rem;
              font-size: 1.125rem;
              height: 2.5rem;
              width: 4.5rem;
              padding: 0px 0.9375rem;
              color: #000000;
            }
          }
        }

        .score-is {
          display: flex;
          align-items: center;
          margin-top: 1.5rem;
          margin-bottom: 1rem;

          > span {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9rem;
            color: #4f4f4f;
          }

          .input-name {
            .ant-input {
              background: #ffffff;
              border: 1px solid #dbdbdb;
              box-sizing: border-box;
              border-radius: 0.25rem;
              font-size: 1.125rem;
              height: 2.5rem;
              width: 4.5rem;
              padding: 0px 0.9375rem;
              color: #000000;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .create-score {
    .score-main {
      .score-calculator {
        h5 {
          padding: 1rem 0.5rem;
        }
        .score-box {
          margin: 0rem 0.65rem;
        }
        .score-calc-buttons {
          margin: 0.5rem;
          .score-calc-item {
            // width: 31%;
          }
        }
      }
      .score-validation {
        .score-validation-body {
          padding: 0.5rem;
          .score-conditions {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            margin-top: 0.5rem;

            .floating-select {
              margin-left: 0rem !important;
              .ant-select {
                .ant-select-selector {
                  width: 12rem;
                  height: 2.5rem;
                  margin: 1rem 0rem;
                  padding: 4px 1rem;
                }
              }
            }

            .floating-between {
              margin-left: 0rem !important;
              .ant-select {
                .ant-select-selector {
                  width: 12rem;
                  height: 2.5rem;
                  margin: 1rem 0rem;
                  padding: 4px 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
