.education {
  background: #f8f9fb;
  // margin-left: 15.625rem;
  flex: 1;
  min-height: 100vh;

  .education-actions {
    margin-top: 2.5rem;
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;

    .education-fields {
      display: flex;
      align-items: center;

      > div {
        margin-right: 1.25rem;
      }
    }
  }

  .table-container {
    padding: 0 2.5rem;

    .ant-table {
      box-shadow: 0 1px 0.25rem rgba(0, 0, 0, 0.16);

      .ant-table-thead {
        background: #f8f9fb;

        .ant-table-cell {
          font-size: 0.875rem;
          color: #757575;
          font-weight: bold;
        }
      }

      .ant-table-tbody {
        tr {
          td {
            font-style: normal;
            font-weight: normal;
            font-size: 0.875rem;
            color: #373737;
          }
        }
      }

      .active {
        .ant-table-cell:nth-child(6) {
          color: #019d1a;
        }
      }
      .inactive {
        .ant-table-cell:nth-child(6) {
          color: #fbb040;
        }
      }
    }

    .ant-table-pagination {
      display: none;
    }

    .table-name {
      display: flex;
      align-items: center;

      div:nth-child(1) {
        height: 3rem;
        width: 3rem;
        overflow: hidden;
        position: relative;
        border-radius: 50%;
        margin-right: 1rem;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      div:nth-child(2) {
        h6 {
          font-size: 0.875rem;
          font-weight: bold;
          color: #373737;
          margin-bottom: 0.2rem;
        }

        p {
          margin: 0;
          font-size: 0.75rem;
          color: #757575;
        }
      }
    }
  }

  .pagination {
    margin: 2.5rem;

    > span {
      padding-top: 0.6rem;
      margin-left: 1.5rem;
      font-size: 0.875rem;
      color: #757575;
    }

    .ant-pagination {
      li {
        margin: 0;
        font-weight: bold;
        border-color: #eee;
        border-radius: 0;
        padding-top: 0.2125rem;
      }

      .ant-pagination-item {
        height: 2.5rem;
        width: 2.5rem;
      }

      .ant-pagination-item a {
        display: block;
        padding: 1px 6px;
        font-size: 0.875rem;
      }

      .ant-pagination-item-active {
        a {
          color: #fbb040;
        }
      }

      .ant-pagination-jump-next {
        min-width: 2.8rem;
        height: 2.2rem;
      }
      .ant-pagination-jump-prev {
        min-width: 2.8rem;
        height: 2.2rem;
      }

      .ant-pagination-prev {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        a {
          border-color: #eee;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 1px 0px;
        }
      }

      .ant-pagination-next {
        height: 2.5rem;
        width: 2.5rem;
        padding: 0;

        a {
          border-color: #eee;
          font-weight: bold;
          font-size: 0.8rem;
          padding: 4px 0px;
        }
      }
    }
  }
}

.add-education-wrapper {
  .input-container {
    .form-label {
      font-style: normal;
      font-weight: normal;
      font-size: 0.95rem;
      color: #5c5c5c;
    }

    .floating-label {
      .ant-input,
      .floating-input {
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 1.125rem;
        height: 2.25rem;
        padding: 0px 0.625rem;
      }
    }

    .floating-select {
      .ant-select {
        width: 100%;
        .ant-select-selector {
          padding: 0.4375rem 4px;
          .ant-select-selection-item {
            height: 30px;
            .ant-select-selection-item-content {
              margin: auto 0px;
              margin-right: 5px;
            }
            .ant-select-selection-item-remove {
              margin: auto 0px;
              margin-bottom: 4px;
            }
          }
        }
        .ant-select-arrow {
          color: #383838;
        }
      }
    }

    .floating-picker {
      .ant-picker {
        width: 100%;
        height: 2.8125rem;
      }
    }
  }

  .button-container {
    width: 100%;
    margin-top: 1.5rem;
    .button-save {
      width: 8.4375rem;
    }

    .button-cancel {
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      text-decoration-line: underline;
      color: #767676;
      /* #767676 */
    }

    .draft {
      width: 8.4375rem;
      border: 1px solid #d8d8d8;
      background: transparent;
      text-decoration: none;
      color: #5c5c5c;
      font-weight: bold;
      padding: 0.75rem 0.5rem;
      border-radius: 0.25rem;
    }
  }

  .image-row {
    .image-container {
      height: 6.6rem;
      width: 10rem;
      border-radius: 0.25rem;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .image-wrapper {
      background: #e0e0e0;
      height: 6.6rem;
      border-radius: 0.25rem;
      position: relative;
    }

    .upload-wrapper {
      display: flex;
      height: 2.625rem;
      align-items: stretch;
      margin-top: auto;

      .ant-input {
        width: auto;
        flex: 1;
      }

      .upload-button {
        border-radius: 0px;
        border: 1px solid $primary-color;
        color: $primary-color;
        background: white;
        padding: 0.63rem 0.75rem;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        font-size: 0.825rem;
      }
    }
  }
}

.completion-lesson {
  margin-top: 2rem;
  text-align: center;

  h4 {
    margin-top: 1rem;
  }

  &-subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #828282;
  }
}
