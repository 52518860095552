.response-container {
  // border: 1px solid red;

  .dual-input {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;

    input[type="text"] {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }

  .choice-response {
    margin-bottom: 1.25rem;

    .response-choice {
      padding: 0.375rem 0.75rem;
      font-weight: 400;
      line-height: 1.5;
      background-color: #fff;
      border: 1px solid #dbdbdb;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .response-code {
      width: 40%;
      margin-right: 1rem;
    }
  }
}
