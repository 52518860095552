.list-pages-wrapper {
  // margin-left: 15.625rem;
  flex: 1;

  .header-block {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.375rem;
      color: #545454;
      font-weight: bold;
      padding-right: 7.5rem;
      padding-bottom: 2.5rem;
      border-bottom: 1px solid #d8d8d8;
    }

    h6 {
      color: #aba5b8;
      font-size: 1.05rem;
      padding-bottom: 2.5rem;
      margin-left: 0.4375rem;
    }
  }

  .draft-mode {
    width: 75px;
    height: 52px;
    margin: 0px;
    background: #ffffff;
    border: 1px solid $primary-color;
    font-style: normal;
    font-weight: 500;
    color: $primary-color;
    padding: 0.4375rem 13.7px;
    font-size: 0.845rem;
    border-radius: 18px;
    margin-left: 0.5rem;
  }

  .search-block {
    // margin-top: 3rem;
    display: flex;
    align-items: center;

    &-import {
      width: 50%;
      text-align: right;
    }

    h3 {
      color: #aba5bb;

      span {
        color: #545454;
      }
    }
  }

  .card-section {
    max-width: 900px;
    .card-content {
      @media (min-width: 767px) {
        display: flex;
        flex-direction: row;
      }
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      // box-shadow: 0px 4px 4px rgba(215, 215, 215, 0.25);
      box-shadow: 0px 4px 4px #f5f5f5;
      border: solid 1px #f5f5f5;
      border-radius: 6px;
      margin: 0 0 0.5rem;
      padding: 1.3rem;
      .card-details {
        display: flex;
        flex-direction: row;
        align-items: center;

        .img-wrapper {
          width: 10.25rem;
          height: 6.375rem;
          background: #ddd;
          position: relative;
          overflow: hidden;
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem;

          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            background-size: 30% auto;
            background-color: rgba(0, 0, 0, 0.1);
            background-image: url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%20282.69%20228%22%20xml…l-168.27%2C0C2.58%2C228-3.8%2C215.71%2C2.48%2C206.79z%22%2F%3E%3C%2Fsvg%3E);
            background-repeat: no-repeat;
            background-position: 50%;
          }
        }

        .card-details-content {
        }
      }
      .edit-pages {
        font-size: 0.9375rem;
        font-family: $font-medium;
        color: #828282;
        margin-right: 2rem;
      }
    }

    .no-page-content {
      margin-top: 4rem;
      h1 {
        font-size: 1.5rem;
        color: #545454;
      }
    }

    .card-content:hover {
      box-shadow: 0 2px 0.625rem 0 rgba(0, 0, 0, 0.2);
    }
  }
}
