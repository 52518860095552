.singer-layout {
  .ant-layout-sider {
    background-color: $sidebar-bg;
    position: relative;
    z-index: 889;
    @media (min-width: 768px) {
      overflow-y: auto;
    }
  }
  .ant-layout-sider-zero-width-trigger {
    background-color: $sidebar-bg;
  }
}
.logo-container {
  margin: 20px;
}
.main-sidebar {
  .item {
    text-decoration: none;
    display: block;
    text-align: center;
    padding: 0.5rem 0;
    &.active {
      position: relative;
      &:before {
        border-left: solid 3px #fff;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 2px;
      }
    }
  }
}
.nav-elements {
  font-size: 0.6rem;
  color: #fff;
  margin: 0.5rem 0 0;
}
