.crf-container {
  width: 100%;
  display: flex;
  height: calc(90vh - 100px);
  margin-top: 20px;
  .crf-sider {
    width: 400px;
    overflow: auto;
    padding: 15px;
    margin-right: 25px;
    background-color: white;
    .ant-collapse {
      border: none;
      border-radius: 4px;
      background-color: white;
      .ant-collapse-item {
        margin-bottom: 10px;
        border: none;
      }
      .ant-collapse-header {
        background-color: white;
        border: 1px solid $input-border-color;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
      }
      .ant-collapse-content {
        border: 1px solid $input-border-color;
        border-top: none;
      }
      .ant-collapse-item-active {
        .ant-collapse-header {
          color: $primary-color;
        }
      }
      .visit {
        font-weight: 500;
        font-size: 16px;
        color: #4f4f4f;
      }
      .selected-visit {
        color: $primary-color;
      }
      .ant-collapse-content-box {
        padding: 0px;
      }
      .block {
        min-height: 80px;
        padding: 15px;
        border-bottom: 1px solid $input-border-color;
        position: relative;
        cursor: pointer;
      }

      .selected-block::before {
        content: " ";
        position: absolute;
        background-color: $primary-color;
        width: 2px;
        height: 100%;
        left: 0px;
        top: 0px;
      }
      .start {
        font-weight: 500;
        font-size: 14px;
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        color: #828282;
      }
      .selected-visit-start {
        color: #30d073;
      }
    }
  }
  .crf-preview-container {
    flex: 1;
    overflow: auto;
    min-width: 1px;
    background-color: white;
    padding: 25px;
    display: flex;
    flex-direction: column;
    .crf-button-container {
      margin-top: auto;
    }
  }
}
