.appointment-tab.singer-cutsom-tab {
  width: 30%;
  .ant-tabs-nav {
    padding: 0px;
    margin-bottom: 1rem;
  }
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      flex: 1;
      min-width: 0px;
      justify-content: center;
    }
  }
  .empty-appointment {
    width: 100%;
    color: $primary-color;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .appointment-item {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    height: 80px;
    margin-bottom: 10px;
    display: flex;
    .time-detail {
      padding-top: 5px;
      width: 81px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      background: #1a334e;
      display: flex;
      flex-direction: column;
      align-items: center;
      .time {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        margin-bottom: 0px;
      }
      .meridien {
        font-weight: 500;
        font-size: 8px;
        line-height: 12px;
        color: #ffffff;
        margin-bottom: 0px;
      }
    }
    .patient-details {
      flex: 1;
      min-width: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 38px;
      padding-right: 21px;
      .patient-name {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #333333;
      }
      .appointment-type {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #828282;
      }
      .appointment-call {
        cursor: pointer;
      }
    }
    .patient-avatar {
      position: absolute;
      left: 59px;
      top: 18px;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary-color;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    }
  }
  .appointment-collapse {
    border: none;
    background-color: transparent;
    .appointment-panel {
      margin-bottom: 10px;
      border-bottom: none;
      .ant-collapse-header {
        background: #f2f2f2;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        color: #4f4f4f;
      }
      .ant-collapse-content {
        background-color: transparent;
        border-top: none;
        .ant-collapse-content-box {
          padding: 0;
          padding-top: 10px;
        }
      }
    }
  }
}
.participant-view-modal {
  .ant-modal-body {
    padding: 0px;
    .ant-table {
      margin-bottom: 0px;
    }
  }
}
.more-users {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #828282;
}
.more-users-count {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: $primary-color;
  cursor: pointer;
}
