.chat-holder {
  flex: 1;
  min-width: 0px;
  overflow: auto;
  scroll-behavior: smooth;
  .empty-chat {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: $sidebar-bg;
  }
  .loading-container,
  .empty-chat {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .next-day-label {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
      background-color: $primary-color;
      border-radius: 8px;
      color: #fff;
    }
  }
  .chat-section {
    background-color: $primary-color;
    border-radius: 8px 8px 8px 0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    max-width: 70%;
    width: fit-content;
  }
  .time-text {
    margin: 5px 0px;
  }
  .chat-attachment-wrapper {
    width: 70%;
    background: #f2f2f2;
    border-radius: 8px 8px 8px 0;
    overflow: hidden;
    .attachment-image {
      max-width: 100%;
      object-fit: contain;
    }
    .attachment-file {
      margin: 10px;
      background-color: #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .attachment-file-name {
        flex: 1;
        min-width: 0px;
        margin-right: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .attachment-text {
      width: 100%;
      background-color: #f2f2f2;
      color: $sidebar-bg;
    }
  }
  .chat-others {
    display: block;
  }
  .chat-me {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .chat-section {
      background: #f2f2f2;
      color: $sidebar-bg;
      border-radius: 8px 8px 0 8px;
    }
    .chat-attachment-wrapper {
      border-radius: 8px 8px 0 8px;
    }
  }
  .show-more {
    font-size: 0.875rem;
    font-weight: 600;
    color: $primary-color;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .chat-link {
    text-decoration: underline;
    cursor: pointer;
  }
}
.chat-editor {
  height: 54px;
  background-color: #f2f2f2;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .chat-input {
    flex: 1;
    min-width: 0px;
    resize: none;
  }
  .chat-input-actions {
    margin-left: 10px;
    .link-wrapper {
      cursor: pointer;
    }
  }
}
.chat-attachment {
  min-height: 50px;
  padding: 10px;
  background-color: #f2f2f2;
  .chat-attachment-content {
    background-color: #fff;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    .label {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $sidebar-bg;
      margin-right: 10px;
    }
    .file-name {
      flex: 1;
      min-width: 0px;
      font-size: 14px;
      line-height: 18px;
      color: $sidebar-bg;
    }
    .attachment-actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      .action-icon {
        color: #bdbdbd;
        margin-left: 5px;
      }
    }
  }
  .chat-attachment-media {
    position: relative;
    height: 250px;
    width: 250px;
    .close-icon {
      position: absolute;
      cursor: pointer;
      top: 15px;
      right: 15px;
      width: 25px;
      height: 25px;
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      font-size: 12px;
      border-radius: 50%;
      text-align: center;
      z-index: 2;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.chat-list {
  .chat-list-view {
    position: relative;
    img {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      border: solid 1px #ddd;
    }

    &:after {
      content: "";
      border-bottom: solid 1px #ddd;
      position: absolute;
      left: 0;
      width: 30%;
      bottom: 0;
    }
    &:hover,
    &.active {
      background-color: $bg-background;
    }
  }
}
.chat-box {
  padding: 1.25rem;
  border-radius: 4px;
  position: relative;
  font-size: 1rem;
  color: rgba(27, 23, 38, 1);
  max-width: 70%;
  &:before {
    content: "";
    width: 0;
    height: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.msg-time {
  margin-bottom: 3rem;
  margin-top: 0.625rem;
}
.message-in {
  .chat-box {
    background: #ebeef8;
    &:before {
      border-right: 8px solid #ebeef8;
      left: -8px;
      border-top: 6px solid transparent;
      border-right: 8px solid #ebeef8;
      border-bottom: 6px solid transparent;
    }
  }
  .msg-time {
    margin-left: 55px;
  }
}
.message-out {
  .chat-box {
    background: rgba(236, 236, 236, 1);
    &:before {
      border-left: 8px solid rgba(236, 236, 236, 1);
      right: -8px;
      border-top: 6px solid transparent;
      border-left: 8px solid #ebeef8;
      border-bottom: 6px solid transparent;
    }
  }
  .msg-time {
    text-align: right;
    margin-right: 55px;
  }
}
.chat-list-holder {
  height: calc(100vh - 215px);
  overflow-y: auto;
}
.input-section {
  height: 40px;
  width: 70%;
}
.media-documnets {
  overflow-y: auto;
  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 0.625rem;
  }
}
.editoR-post {
  h6 {
    font-size: 13px;
    top: 3px;
    position: relative;
  }
}
.chat-group-holder {
  height: calc(100vh - 130px);
  overflow-y: auto;
}
