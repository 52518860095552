.question-builder {
  .sub-header {
    .title-form {
      margin-top: 2.5rem;
      color: #545454;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: capitalize;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      color: #a0a5b8;
      width: 50%;
    }

    .upload-button {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(135, 136, 249, 0.4);
      border-radius: 4px;
      font-weight: 500;
      font-size: 1rem;
      color: $primary-color;
      border: 2px solid $primary-color;
    }
  }

  .builder-interface {
    margin-top: 1.25rem;

    .question-list {
      min-height: 28.125rem;
      padding: 5px;
      margin-bottom: 10px;
    }

    .drop-area {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 33vh;
      border: 2px dashed #bbb;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      padding: 50px;
      background: rgb(248, 249, 251);
      #dropZone {
        text-align: center;
        font: 21pt bold arial;
        color: #bbb;
      }
    }

    .choices {
      // padding: 0 2rem;

      h5 {
        color: #5c5c5c;
        font-weight: bold;
        font-size: 1.15rem;
      }

      p {
        color: #a0a5b8;
      }

      .choice-items {
        .grid-choice {
          padding-bottom: 0.75rem;

          .choice-item {
            display: flex;
            align-items: center;
            border: 1px solid $primary-color;
            border-radius: 0.25rem;
            padding: 0.375rem;
            cursor: grab;

            span {
              color: $primary-color;
              font-size: 0.875rem;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-family: $font-medium;
            }
          }
          @media (max-width: 767px) {
            top: 0;
            width: auto;
            .choice-item {
              width: auto;
              .multichoice-icon {
                margin-right: 0;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    .choice-buttons {
      width: 100%;
      margin: 2.5rem 0;

      .choice-check-box {
        margin-bottom: 1rem;
        > span {
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          color: #5c5c5c;
          margin-left: 0.6rem;
        }
      }

      button[type="button"] {
        height: 3rem;
        padding: 0.5rem 1rem;
      }

      .grey-button {
        background: #ddd !important;
        color: #5c5c5c !important;
      }
    }

    .grey-layout {
      background-color: $bg-background;
      border: 1px solid $input-border-color;
      // background-color: #f8f9fb;
      // border: 1px solid #dbdbdb;
      border-radius: 4px;
      // padding: 1rem 5.375rem 1rem 1.375rem;
      // padding: 2rem;
      // position: fixed;
      // top: 18%;
      // margin-right: 40px;
      @media (max-width: 767px) {
        margin-bottom: 50px;
      }
    }
  }
}

.logic-jump {
  .cards-logic {
    max-height: 35rem;
    overflow-y: auto;
  }
  .q-card {
    background: #ffffff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  }

  .add-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .logic-card {
    // margin-bottom: 1.25rem;
    // margin: 0.5rem 1rem 1.25rem 1rem;
    margin: 20px 5px;
  }

  .logic-text {
    font-size: 1rem;
  }

  .logic-if {
    display: flex;
    align-items: center;
  }

  .add-jump {
    background-color: rgb(38, 38, 39) !important;
    white-space: nowrap;
    transition: all 0.4s ease 0s;
    border-radius: 0.375rem;
    padding: 0.375rem 1.125rem;
    height: 2.25rem;
  }

  // .save-btn {
  //   height: 2.5rem;
  //   padding: 0.5rem 2.5rem;
  // }
}

@media screen and (max-width: 1024px) {
  .question-builder {
    .builder-interface {
      .choices {
        padding: 0rem;
      }
      .grey-layout {
        padding: 1rem 1.375rem;
        // margin-right: 7px;
      }

      .choice-buttons {
        margin: 4rem 0;
        button[type="button"] {
          height: 2.5rem;
          padding: 5px 10px;
        }
      }
    }
  }
}
