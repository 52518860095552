.question-preview {
  .sub-header {
    .title-form {
      margin-top: 2.5rem;
      color: #545454;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: capitalize;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      color: #a0a5b8;
      width: 50%;
    }

    .upload-button {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgba(135, 136, 249, 0.4);
      border-radius: 4px;
      font-weight: 500;
      font-size: 1rem;
      color: $primary-color;
      border: 2px solid $primary-color;
    }
  }

  .header-block {
    display: flex;
    align-items: center;

    h1 {
      font-size: 1.375rem;
      color: #545454;
      font-weight: bold;
      padding-right: 7.5rem;
      padding-bottom: 2.5rem;
      border-bottom: 1px solid #d8d8d8;
    }

    h6 {
      color: #aba5b8;
      font-size: 1.05rem;
      padding-bottom: 2.5rem;
    }
  }
}
.preview-button {
  // position: absolute;
  // right: 50px;
  text-align: right;
}

.upform {
  .upform-main {
    .input-block {
      .input-control {
        margin: 20px 0 0;
      }

      .input-control-bar {
        margin: 25px 0 65px 0;
      }

      .choice-items {
        max-width: 100%;
        min-width: 100%;
        display: inline-flex;
        flex-wrap: wrap;
        flex-direction: row;
        -webkit-box-align: stretch;
        align-items: stretch;
      }

      &-required {
        color: tomato;
        padding-left: 5px;
      }
    }

    .input-block.active {
      opacity: 1;
    }

    .input-block .label {
      display: block;
      font-size: 1.125rem;
      line-height: 30px;
    }

    .input-block .label-group {
      display: block;
      font-family: $font-medium;
      background-color: #f2f2f2;
      padding: 1rem;
    }

    .input-block .label-pw {
      display: block;
      font-size: 1.25rem;
      line-height: 30px;
    }
  }

  /** Form Input Field */
  input,
  select,
  textarea {
    background-color: #fff !important;
    color: #ccc;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
    color: #000;
    border-color: #000 !important;
    transition: 600ms;
  }

  // progress bar

  .upform-main .input-block .input-control-bar {
    .container-bar {
      width: 100%;
    }
    //   .progressbar li.active {
    //     list-style-type: none;
    //     float: left;
    //     width: 20%;
    //     position: relative;
    //     text-align: center;
    //     color: #999999;
    //     cursor: pointer;
    //   }
    //   .progressbar li:hover {
    //     list-style-type: none;
    //     float: left;
    //     width: 20%;
    //     position: relative;
    //     text-align: center;
    //     color: #000;
    //     cursor: pointer;
    //     // text-shadow: 10px 10px 10px #000000;
    //   }
    //   .progressbar li {
    //     background-color: red;
    //   }
    //   .progressbar li:before {
    //     content: "";
    //     width: 30px;
    //     height: 30px;
    //     border: 1px solid #ddd;
    //     display: block;
    //     text-align: center;
    //     margin: 0px auto 10px auto;
    //     border-radius: 50%;
    //     background-color: #000;
    //   }
    //   .progressbar li:before:hover {
    //     content: "";
    //     width: 30px;
    //     height: 30px;
    //     border: 1px solid #ddd;
    //     display: block;
    //     text-align: center;
    //     margin: 0px auto 10px auto;
    //     border-radius: 50%;
    //     background-color: #000;
    //     box-shadow: 0 6px 20px 0 black, 0 6px 20px 0 black;
    //   }

    //   .progressbar li:after {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 3px;
    //     background-color: #000;
    //     top: 15px;
    //     left: -50%;
    //   }

    //   .progressbar li.active:first-child::after {
    //     content: none;
    //   }
    //   .progressbar li.activated {
    //     list-style-type: none;
    //     float: left;
    //     width: 20%;
    //     position: relative;
    //     text-align: center;
    //     color: #000;
    //   }
    //   .progressbar li.activated::before {
    //     content: "";
    //     width: 30px;
    //     height: 30px;
    //     border: 3px solid #ffffff;
    //     display: block;
    //     text-align: center;
    //     margin: 0px auto 10px auto;
    //     border-radius: 50%;
    //     background-color: #000000;
    //     color: #000000;
    //     box-shadow: 0 6px 20px 0 black, 0 6px 20px 0 black;
    //   }

    //   .progressbar li.activated::after {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 3px;
    //     background-color: #000;
    //     top: 15px;
    //     left: -50%;
    //   }

    //   .progressbar li.activated:first-child::after {
    //     content: none;
    //   }
    // }
  }

  /** End Form Input Field */

  /** Form Radio Button */

  input[type="radio"].toggle {
    display: none;
  }

  input[type="radio"].choice {
    display: none;
  }

  input[type="radio"].opinion {
    display: none;
  }

  input[type="radio"].toggle + label {
    cursor: pointer;
    min-width: 80px;
  }

  input[type="radio"].choice + label {
    display: block;
    // max-width: 200px;
    cursor: pointer;
    border-radius: 4px;
  }

  input[type="radio"].opinion + label {
    cursor: pointer;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  input[type="radio"].toggle + label:hover {
    background-color: #000;
    color: #fff;
  }

  input[type="radio"].toggle:checked + label {
    background-color: #000;
    color: #fff;
    cursor: default;
    border-radius: 8px;
  }

  /** Form Check Box */

  input[type="checkbox"].toggle {
    display: none;
  }

  input[type="checkbox"].choice {
    display: none;
  }

  input[type="checkbox"].opinion {
    display: none;
  }

  input[type="checkbox"].toggle + label {
    cursor: pointer;
    min-width: 80px;
  }

  input[type="checkbox"].choice + label {
    display: block;
    // max-width: 200px;
    cursor: pointer;
    border-radius: 8px;
  }

  input[type="checkbox"].opinion + label {
    cursor: pointer;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  input[type="checkbox"].toggle + label:hover {
    background-color: #000;
    color: #fff;
  }

  input[type="checkbox"].toggle:checked + label {
    background-color: #000;
    color: #fff;
    cursor: default;
    border-radius: 8px;
  }

  // input[type='radio'].toggle:checked + .text-label span {
  //   background-color: #000;
  //   color: #fff;
  //   border: none;
  // }

  .text-label {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px 10px;
    font-size: 0.875rem;
    position: relative;
    text-align: left;
    border-radius: 3px;
    -webkit-transition: background-color 600ms ease, color 600ms ease;
    -moz-transition: background-color 600ms ease, color 600ms ease;
    -o-transition: background-color 600ms ease, color 600ms ease;
    transition: background-color 600ms ease, color 600ms ease;
  }

  .text-label span {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 1px 6px;
    font-size: 12px;
    border-radius: 3px;
    vertical-align: middle;
    text-align: center;
    margin-top: -5px;
  }

  input[type="radio"].toggle.toggle-right + label {
    margin-left: 10px;
  }

  .systm-select {
    // font-size: 20px;
    color: #ccc;
    border-bottom: 2px solid #ccc !important;
    .ant-select-selector {
      border: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      padding: 10px 5px !important;
      height: 45px !important;
      min-width: 300px !important;
      background-color: #fafafa !important;
    }

    .ant-select-selection-item:hover {
      color: #000;
      transition: 600ms;
    }
  }

  .systm-select:focus,
  .systm-select:hover {
    outline: none;
    border-color: #000 !important;
    transition: 600ms;
  }

  .date-selector {
    max-width: 300px;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 25px;
    background-color: #fafafa !important;
    color: #ccc;
    border-bottom: 2px solid #ccc;

    .ant-picker-input > input:hover {
      color: #000;
    }
  }

  .date-selector:focus,
  .date-selector:hover {
    color: #000;
    border-color: #000;
    transition: 600ms;
  }

  .img-wrapper {
    width: 100%;
    height: 250px;
    margin-bottom: 10px;
    background-color: #ccc;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .no-items {
    font-size: 1rem;
  }

  /** Footer Submit Button **/

  .upform-footer .btn {
    font-size: 1.2rem;
    padding: 5px 20px;
    border: none;
    outline: none;
  }

  /** End Footer Submit Button **/
}
