.navigation {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 84px);
  top: 0;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 888;
  left: 0;
  width: 100%;

  .actions {
    display: flex;
    margin-left: 95px;
    padding: 0 0 0 20px;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    height: $navbar-height;
    .action-button {
      padding: 1.25rem;
    }
    .notification {
      margin-right: 0.6rem;

      .image-container-nav {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 5rem;
        position: relative;
        .ant-avatar {
          display: inline-block;
          height: 100%;
          width: 100%;
          vertical-align: top;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5rem;
        }
      }
    }
  }
}

.user {
  height: 100%;
  background-color: $color-nav-light;
  padding: 1rem;
  display: flex;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0.8rem 0.675rem;

  li {
    color: #5c5c5c;
    font-size: 1rem;
  }
}

.ant-popover-inner {
  box-shadow: 0px 1px 4px rgba(135, 136, 249, 0.4);
  border-radius: 0.25rem;
  padding: 0;

  .ant-popover-inner-content {
    padding: 0;
    min-width: max-content;
    li {
      padding: 0.4rem 0rem;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
