.new-section-wrapper {
  flex: 1;

  .mt {
    margin-top: 4.375rem;
  }

  .new-section-header {
    h1 {
      color: #545454;
      border-bottom: 1px solid #d8d8d8;
    }

    h6 {
      // padding-left: 7.5rem;
      color: #aba5b8;
      font-size: 1.05rem;
      line-height: 2rem;
    }
  }

  .main-body {
    .main-body-column1 {
      @media (min-width: 991px) {
        border-right: 1px solid #d8d8d8;
      }
      .header-choose {
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        color: #a0a5b8;
      }

      .card-section {
        margin-top: 3.75rem;
        display: flex;
        flex-direction: row;
        text-align: center;
        flex-wrap: wrap;
        padding-bottom: 3.5rem;
        border-bottom: 1px solid #d8d8d8;
        .card-section-details {
          padding: 1rem;
          background: #ffffff;
          border: 1px solid rgba(160, 165, 184, 0.4);
          box-sizing: border-box;
          border-radius: 0.25rem;
          @media (min-width: 991px) {
            height: 14.375rem;
          }

          .A-title,
          .video-section,
          .A-pictures,
          .A-article,
          .A-question,
          .A-resources {
            width: 2rem;
            height: 2rem;
            margin-bottom: 0.5rem;
          }
          .card-title {
            font-style: normal;
            font-weight: bold;
            font-size: 0.9375rem;
            color: #545454;
            margin-top: 0.25rem;
          }
          .card-desc {
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375rem;
            color: #a0a5b8;
            line-height: 25px;
          }
        }

        .card-section-color {
          background: $primary-color;
          border-radius: 0.25rem;
          .A-video {
            width: 2rem;
            height: 2rem;
          }
          .card-title {
            color: #fff;
          }
          .card-desc {
            color: #fff;
          }
        }
      }

      .button-save {
        padding: 0px 0.9375rem;
      }
    }
    .main-body-column2 {
      padding: 2rem;
      @media (min-width: 991px) {
        padding: 1.875rem 3.125rem;
      }
      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 1.375rem;
        color: #545454;
      }

      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.0625rem;
        color: rgba(84, 84, 84, 0.9);
      }
    }
    .secction-list-container {
      margin-top: 1.25rem;
      width: 100%;
      background: rgba(196, 196, 196, 0.14);
      border-radius: 0.125rem;
      @media (min-width: 991px) {
        width: 26.25rem;
      }
      .section-list-header {
        background: #ffffff;
        border: 1px solid rgba(160, 165, 184, 0.4);
        box-sizing: border-box;
        border-radius: 0.125rem;
        font-style: normal;
        font-weight: 500;
        font-size: 0.9375rem;
        padding: 0.9375rem;
      }
      .section-list-item {
        overflow-y: auto;
        height: 31.25rem;
      }
      .section-content {
        display: flex;
        align-items: center;

        margin: 0px 0.3125rem;
        width: 95%;
        height: 5.75rem;
        background: #ffffff;
        border: 1px solid rgba(160, 165, 184, 0.4);
        box-sizing: border-box;

        .section-content-details {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 70%;
          &-title {
            font-style: normal;
            font-weight: 500;
            font-size: 0.9375rem;
            color: #545454;
          }
          &-preview {
            font-style: normal;
            font-weight: 500;
            font-size: 0.9375rem;
            text-decoration-line: underline;
            color: rgba(84, 84, 84, 0.5);
          }
        }
      }
    }
  }

  .Buttons-lessons {
    width: 60%;
    .cancel-button {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      text-decoration-line: underline;
      color: #767676;
    }

    .button-save {
      // padding: 0.75rem 1.5625rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .new-section-wrapper {
    .padding-common {
      .main-body {
        .main-body-column2 {
          padding: 1.875rem 1.125rem;
          .secction-list-container {
            margin-top: 1.25rem;
            width: 16.25rem;
            background: rgba(196, 196, 196, 0.14);
            border-radius: 0.125rem;
          }
        }
      }
    }
  }
}
