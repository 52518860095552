.edit-lesson-wrapper {
  width: 60%;

  .field-title {
    font-style: normal;
    font-weight: 500;
    // font-size: .875rem;
    font-size: 1rem;
    color: black;
    margin-bottom: 0.375rem;
  }
  .field-group {
    .floating-label {
      .ant-input,
      .floating-input {
        background: #ffffff;
        border: 1px solid #dbdbdb;
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 1.125rem;
        height: 2.5rem;
        padding: 0px 0.3125rem;
        margin: 0.3125rem 0px;
      }
    }
  }

  .text-editor-wrapper {
    background: #fff;
    flex: 1;

    .text-editor {
      margin-top: 2.5rem;
    }

    .quill {
      .ql-toolbar {
        .ql-formats {
          padding: 1rem 0px;
          padding-right: 1.8rem;
        }
      }
      .ql-container {
        height: 20vh;
      }
    }
  }

  .resources-section {
    margin-top: 2.5rem;

    .add-extra {
      font-size: 0.875rem;
    }
  }

  .pictures-section {
    margin: 1.25rem 0px;
    .pictures-wrapper {
      display: flex;
      margin-top: 0.5rem;

      .image-container {
        width: 10rem;
        height: 7.35rem;
        background: #e0e0e0;
        margin-right: 0.75rem;
        border-radius: 0.125rem;
        position: relative;
      }

      .image-url-wrapper {
        height: 7.35rem;
        width: 10rem;
        border-radius: 0.25rem;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        margin-bottom: 0.95rem;
        color: #5c5c5c;
      }

      .upload-bar {
        display: flex;
        .ant-input {
          width: 15.625rem;
          height: 2.625rem;
        }
        .upload-button {
          border-radius: 0px;
          border: 1px solid $primary-color;
          color: $primary-color;
          background: white;
          padding: 0.58rem 0.75rem;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
  .questions-section {
    margin-top: 1.25rem;
    width: 32.5rem;

    .choice-field {
      position: relative;
      .remove-question {
        position: absolute;
        top: 0.4375rem;
        right: 0.625rem;
        transform: rotate(45deg);
        font-size: 1.5rem;
      }
      .choice-field-select {
        margin-bottom: 0.5rem;
        .floating-select {
          .ant-select {
            width: 11.25;
            .ant-select-selector {
              padding: 2px 0.625rem;
              .ant-select-selection-item {
                height: 30px;
                .ant-select-selection-item-content {
                  margin: auto 0px;
                  margin-right: 5px;
                }
                .ant-select-selection-item-remove {
                  margin: auto 0px;
                  margin-bottom: 4px;
                }
              }
            }
            .ant-select-arrow {
              color: #383838;
            }
          }
        }
      }
    }
  }
}
