.commenter-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .moderator-label {
    margin-top: 10px;
    padding: 4px 8px;
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 4px;
  }
}
